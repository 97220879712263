import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const HelmetWrapper = ({ title, description, canonicalUrl }) => {
  useEffect(() => {
    // Remove static canonical tag if React Helmet is being used
    const staticCanonicalTag = document.getElementById("static-canonical");
    const staticDescriptionTag = document.getElementById("static-description");

    if (staticCanonicalTag) {
      staticCanonicalTag.parentNode.removeChild(staticCanonicalTag);
    }
    if (staticDescriptionTag) {
      staticDescriptionTag.parentNode.removeChild(staticDescriptionTag);
    }
  }, []);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default HelmetWrapper;
